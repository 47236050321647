import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Loader } from '@elfsight-universe/ui-common';
import { HeadController } from '@components';
import {
  NextPageWithConfig,
  useAppCurrentAppAliasContext
} from '@modules/_app';
import { DashboardStartPage } from '@modules/dashboard/dashboard-start-page';

const Apps: NextPageWithConfig = () => {
  const { replace } = useRouter();
  const { currentAppAlias, isCurrentAppAliasFetched } =
    useAppCurrentAppAliasContext();

  useEffect(() => {
    if (currentAppAlias) {
      replace(`/apps/${currentAppAlias}`);
    }
  }, [currentAppAlias]);

  if (isCurrentAppAliasFetched && !currentAppAlias) {
    return (
      <>
        <HeadController title="My Apps" />
        <DashboardStartPage />
      </>
    );
  }

  return (
    <>
      <HeadController title="My Apps" />
      <Loader absolute />
    </>
  );
};

Apps.config = {};

export default Apps;
